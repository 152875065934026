require('../css/overlay.scss');

var $ = require('jquery');

Overlay = (function () {
    var self = {};

    // Public Function display - Définit le contenu de l'overlay et l'affiche
    //
    // @param {icon} - class Font Awesome
    // @param {text} - text to display
    self.display = function (icon, text) {
        $('.overlay-container__label').text(text);
        $('.overlay-container__icon').html("<i class='" + icon + "'></i>");
        $('.overlay').fadeIn();
    };

    // Public Fonction hide - Masque l'overlay
    //
    self.hide = function (animationTime = 'slow') {
        $('.overlay').fadeOut(animationTime);
    };

    return self;
})();

OverlayContent = (function () {
    var self = {};

    // Public Function display - Définit le contenu de l'overlay et l'affiche
    //
    // @param {icon} - class Font Awesome
    // @param {text} - text to display
    self.display = function (id, icon, text) {

        $('#' + id + ' .overlay-content-container__label').text(text);
        $('#' + id + ' .overlay-content-container__icon').html("<i class='" + icon + "'></i>");
        $('#' + id + ' .overlay-content').fadeIn();
    };

    // Public Fonction hide - Masque l'overlay
    //
    self.hide = function (id, animationTime = 'slow') {
        $('#' + id + ' .overlay-content').fadeOut(animationTime);
    };

    return self;
})();
