// any CSS you import will output into a single css file (app.css in this case)
require('../css/minisite-bootstrap-5.scss');
require('../js/overlay.js');

import 'bootstrap-icons/font/bootstrap-icons.css';

const routes = require('../../public/js/fos_js_routes.json');
import Routing from '../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
global.Routing = Routing;
Routing.setRoutingData(routes);

//jQuery
const $ = require('jquery');
global.$ = global.jQuery = $;


// Bootstrap
//const popperjs = require('@popperjs/core/dist/umd/popper.min.js');
//global.popper = popperjs;

const bootstrap5 = require('bootstrap-5/dist/js/bootstrap.bundle.min.js');
global.bootstrap5 = bootstrap5;
//HOLDER JS (Plugin pour les dimension des image)
require('holderjs/holder.min');